.content-page {
  h3 {
    font-size:18px;
    margin:2rem 0 1rem 0;
  }
  .legal-number, .legal-text {
    color:#6b7c93;
  }
  .legal-text {
    display:block;
    margin-bottom:1rem;
  }
}
