.mfp-close-btn-in .mfp-close{
  color:white;
}
#register-form {
  width:100%;
  height:100%;
  min-height:100vh;
  background:white;
  background:url("../img/slider/slider_bg.png");

  .register-form-inner{
    width:400px;
    margin:0 auto;
    h4 {

      color:white;
    }

    form {
      input {
        background:none;
        border-radius:0;
        margin:10px 0;
        color:white;
      }
    }
    .register-logo {
      padding-top:100px;
      margin-bottom:20px;
      text-align:center;
      img {
        max-width:200px;
      }
    }
    .form-check-label {
      margin-left: 20px;
      margin-top: 6px;
      color:white;
      a {
        color:#0eebb1;
      }
    }
    .btn{
      width:100%;
      margin-top:10px;
      color:white;
      border-radius:0;
    }
    .form-check {
      padding-left:0
    }
  }
}
.mfp-container{
  padding:0;
}
.mfp-wrap{
  z-index:10000;
}

.mfp-zoom-in {

  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }

  }

}
.mfp-hide {
  display:none;
}

.nav-tabs.register-tabs {
  margin:20px 0;
  .nav-link {
    font-size: 14px;
    padding: 15px 12px;
  }
}

