.slider-bg {
  min-height:950px;
}
.slider-img {
  position:relative;
  img {
    max-width:100%;
  }
  .slider-img-overlay {
    position:absolute;
    left: 72px;
    width: 411px;
    top: 211px;
  }
}
.chosting-img {
  img {
    max-width: 100%;
  }
}
.slick-slider .slick-track {
  display:flex;
  align-items: center;
}
