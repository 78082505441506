.icon-round {
  display:inline-block;
    width:70px;
  height:70px;
  background-size:70%;
  padding:10px;
  border-radius:40px;
  background-position:center;
  background-repeat:no-repeat;
  &-yellow {
    background-color:#f8cb53;
  }
  &-turkey {
    background-color:#09ebaf;
  }
  &-purple {
    background-color:#c954ff;
  }
}

.icon-list {
  background-image:url("../img/icon/icon-list-blue.png");
}
.icon-choose {
  background-image:url("../img/icon/icon-choose-blue.png");
}
.icon-prize {
  background-image:url("../img/icon/icon-prize-blue.png");
}
.nav-link-icon {
  max-width:35px;
}
