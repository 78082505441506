.shosting-plan-toggle .toggle {
  background:#0eebb1;
}
.shosting-plan-toggle .toggler {
  color:#ccc;
  &.toggler--is-active{
    color:#0eebb1;
  }
}
.hplan-list {
    span.strike {
      color:#ccc;
      text-decoration:line-through;
      margin-bottom:0;
      font-weight:normal;
    }
}
