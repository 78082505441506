@media (max-width:1200px) {
  .slider-btn {
    .btn {
      margin:0;
      &.transparent-btn {
        display:none;
      }
    }
  }
}
@media (max-width:990px) {
  .mean-container a.meanmenu-reveal {
    margin-top:-58px;
  }
}
