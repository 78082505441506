html {
  scroll-behavior: smooth;
}

header#header-sticky .logo {
  width: 200px;
}
header#header-sticky .btn {
  font-size: 12px;
  padding: 15px 15px;
}

.no-slider header {
  background: #1a196e;
  background: -webkit-linear-gradient(90deg, #1a196e, #4e8dd6);
  background: linear-gradient(90deg, #1a196e, #4e8dd6);
  -webkit-animation: fadeInDown 1s ease-in-out 0s 1 normal none running;
  animation: fadeInDown 1s ease-in-out 0s 1 normal none running;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
}

.slider-bg {
  min-height: 950px;
}

.slider-img {
  position: relative;
}
.slider-img img {
  max-width: 100%;
}
.slider-img .slider-img-overlay {
  position: absolute;
  left: 72px;
  width: 411px;
  top: 211px;
}

.chosting-img img {
  max-width: 100%;
}

.slick-slider .slick-track {
  display: flex;
  align-items: center;
}

.shosting-plan-toggle .toggle {
  background: #0eebb1;
}

.shosting-plan-toggle .toggler {
  color: #ccc;
}
.shosting-plan-toggle .toggler.toggler--is-active {
  color: #0eebb1;
}

.hplan-list span.strike {
  color: #ccc;
  text-decoration: line-through;
  margin-bottom: 0;
  font-weight: normal;
}

.icon-round {
  display: inline-block;
  width: 70px;
  height: 70px;
  background-size: 70%;
  padding: 10px;
  border-radius: 40px;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-round-yellow {
  background-color: #f8cb53;
}
.icon-round-turkey {
  background-color: #09ebaf;
}
.icon-round-purple {
  background-color: #c954ff;
}

.icon-list {
  background-image: url("../img/icon/icon-list-blue.png");
}

.icon-choose {
  background-image: url("../img/icon/icon-choose-blue.png");
}

.icon-prize {
  background-image: url("../img/icon/icon-prize-blue.png");
}

.nav-link-icon {
  max-width: 35px;
}

.mfp-close-btn-in .mfp-close {
  color: white;
}

#register-form {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: white;
  background: url("../img/slider/slider_bg.png");
}
#register-form .register-form-inner {
  width: 400px;
  margin: 0 auto;
}
#register-form .register-form-inner h4 {
  color: white;
}
#register-form .register-form-inner form input {
  background: none;
  border-radius: 0;
  margin: 10px 0;
  color: white;
}
#register-form .register-form-inner .register-logo {
  padding-top: 100px;
  margin-bottom: 20px;
  text-align: center;
}
#register-form .register-form-inner .register-logo img {
  max-width: 200px;
}
#register-form .register-form-inner .form-check-label {
  margin-left: 20px;
  margin-top: 6px;
  color: white;
}
#register-form .register-form-inner .form-check-label a {
  color: #0eebb1;
}
#register-form .register-form-inner .btn {
  width: 100%;
  margin-top: 10px;
  color: white;
  border-radius: 0;
}
#register-form .register-form-inner .form-check {
  padding-left: 0;
}

.mfp-container {
  padding: 0;
}

.mfp-wrap {
  z-index: 10000;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-hide {
  display: none;
}

.nav-tabs.register-tabs {
  margin: 20px 0;
}
.nav-tabs.register-tabs .nav-link {
  font-size: 14px;
  padding: 15px 12px;
}

.content-page h3 {
  font-size: 18px;
  margin: 2rem 0 1rem 0;
}
.content-page .legal-number, .content-page .legal-text {
  color: #6b7c93;
}
.content-page .legal-text {
  display: block;
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
  .slider-btn .btn {
    margin: 0;
  }
  .slider-btn .btn.transparent-btn {
    display: none;
  }
}
@media (max-width: 990px) {
  .mean-container a.meanmenu-reveal {
    margin-top: -58px;
  }
}
/* Cookie Dialog */
#gdpr-cookie-message {
  position: fixed;
  left: 30px;
  bottom: 30px;
  max-width: 450px;
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
  margin-left: 30px;
  z-index: 20;
  font-family: system-ui;
}

#gdpr-cookie-message h4 {
  color: #09ebaf;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

#gdpr-cookie-message h5 {
  color: #09ebaf;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

#gdpr-cookie-message p, #gdpr-cookie-message ul {
  color: white;
  font-size: 15px;
  line-height: 1.5em;
}

#gdpr-cookie-message p:last-child {
  margin-bottom: 0;
  text-align: right;
}

#gdpr-cookie-message li {
  width: 49%;
  display: inline-block;
}

#gdpr-cookie-message a {
  color: #09ebaf;
  text-decoration: none;
  font-size: 15px;
  padding-bottom: 2px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in;
}

#gdpr-cookie-message a:hover {
  color: white;
  border-bottom-color: #09ebaf;
  transition: all 0.3s ease-in;
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
  border: none;
  background: #09ebaf;
  color: white;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  padding: 7px;
  border-radius: 3px;
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

button#gdpr-cookie-advanced {
  background: white;
  color: black;
}

#gdpr-cookie-message button:disabled {
  opacity: 0.3;
}

#gdpr-cookie-message input[type=checkbox] {
  float: none;
  margin-top: 0;
  margin-right: 5px;
}