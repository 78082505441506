header#header-sticky {
  .logo {
    width:200px;
  }

  .btn {
    font-size: 12px;
    padding: 15px 15px;
  }
}
.no-slider header {
  background: #1a196e;
  background: -webkit-linear-gradient(90deg,#1a196e,#4e8dd6);
  background: linear-gradient(90deg,#1a196e,#4e8dd6);
  -webkit-animation: fadeInDown 1s ease-in-out 0s 1 normal none running;
  animation: fadeInDown 1s ease-in-out 0s 1 normal none running;
  -webkit-box-shadow: 0 10px 15px rgba(25,25,25,.1);
}
